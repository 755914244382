import React from "react";
import {
    Table,
    TableBody,
    TableCaption,
    TableDataCell,
    TableHead,
    TableHeaderCell,
    TableRow, TableRowExpandable
} from "@sb1/ffe-tables-react";
import {InitialSort} from "@sb1/ffe-tables-react/types/types";
import classNames from "classnames";

interface Props {
    className?: string;
    columns: Column[];
    data: Data[];
    sortable?: boolean;
    sortBy?: string;
    descending?: boolean;
    caption?: string;
    expandedContentMapper?: (row: any) => React.ReactNode;
    condensed?: boolean,
    offset?: number,
    limit?: number,
}

export interface Column {
    key: string;
    header: string;
    cellRender?: (value: any, column: Column, props: any) => React.ReactNode;
    compare?: (a: any, b: any) => number;
}

export interface TableRowProps {
    cells: any;
}

export type Data = any;

const LegacyTable: React.FunctionComponent<Props> = (
    {className, columns, caption, sortable, sortBy, descending, offset, limit, expandedContentMapper, condensed, data}
) => {
    const initialSort: InitialSort | undefined = sortable && sortBy ? { sortKey: sortBy, sortOrder: descending ? "descending" : "ascending"} : undefined;

    const defaultSortFunction = (key: string) => (a: any, b: any) => {
        if (!a[key] && b[key]) {
            return 1;
        } else if (a[key] && !b[key]) {
            return -1;
        } else if (!a[key] && !b[key]) {
            return 0;
        } else if (typeof a[key] === "number") {
            return a[key] - b[key]
        } else {
            return a[key].localeCompare(b[key]);
        }
    }

    return <Table className={classNames({"ffe-table--condensed": condensed}, className)} initialSort={initialSort}>{({ activeSortKey, activeSortOrder }) => {
        const ascendingSortFunc = activeSortKey
            ? defaultSortFunction(activeSortKey)
            : null;

        const sortFunc = ascendingSortFunc && (activeSortOrder === "ascending" ? ascendingSortFunc : (a,b) => ascendingSortFunc(b,a));

        const sortedData = sortFunc
            ? data.toSorted(sortFunc)
            : data;

        const renderRow = (row: any) => {
            return columns.map((column, index) => (
                <TableDataCell key={index} columnHeader={column.header}>
                    {column.cellRender ? column.cellRender(row[column.key], column, { cells: row }) : row[column.key]}
                </TableDataCell>
            ))}


        return (
            <>
                { caption ? <TableCaption>Masse spennende data</TableCaption> : null }
                <TableHead>
                    <TableRow>
                        {columns.map((column, index) => (
                            <TableHeaderCell key={index} sortKey={column.key} scope="col">
                                {column.header}
                            </TableHeaderCell>
                        ))}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {sortedData.slice(offset, limit ? (offset ?? 0) + limit : undefined).map((row, index) =>
                        expandedContentMapper ?
                            <TableRowExpandable
                                key={index}
                                expandContent={expandedContentMapper(row)}
                            >
                                {renderRow(row)}
                            </TableRowExpandable>
                            :
                            <TableRow key={index}>
                                {renderRow(row)}
                            </TableRow>
                    )}
                </TableBody>
            </>
        );
    }}
    </Table>
}
export default LegacyTable;
