import React from 'react';
import {BrowserRouter} from 'react-router-dom';
import {Route, Routes} from 'react-router';
import InnloggetBrukerProvider from '~/components/felles/InnloggetBruker';
import Settings from '~/components/felles/Settings';
import Utlogget from '~/components/felles/Utlogget';
import TrackingSetup from '~/components/tracking/TrackingSetup';
import TilbudContextProvider from '~/components/tilbud/TilbudContext';

import '~/less/index.less';
import Hovedflyt from '~/Hovedflyt';
import {QueryClient, QueryClientProvider} from "@tanstack/react-query";

export const maksAntallAnsatte = 24;

export const queryClient = new QueryClient({defaultOptions: { queries: {retry: 1, refetchOnMount: false, refetchOnWindowFocus: false } }});

function App() {
    const basename = '/ksmb/';

    return <BrowserRouter basename={basename}>
        <QueryClientProvider client={queryClient}>
            <Settings>
                <TilbudContextProvider>
                    <TrackingSetup />
                    <InnloggetBrukerProvider>
                        <Routes>
                            <Route path={'/utlogget'} element={<Utlogget signert={false} />} />
                            <Route path={'*'} element={<Hovedflyt />} />
                        </Routes>
                    </InnloggetBrukerProvider>
                </TilbudContextProvider>
            </Settings>
        </QueryClientProvider>
    </BrowserRouter>
}

export default App;
