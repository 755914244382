import React from 'react';
import {RadioButtonInputGroup, RadioButtonInputGroupProps, RadioSwitch} from "@sb1/ffe-form-react";
import {UseFormSetValue} from "react-hook-form";

interface Props extends Omit<RadioButtonInputGroupProps, 'children' | 'ref'> {
    setValue: UseFormSetValue<any>;
    children?: React.ReactNode // For å gjøre children valgfri
}

const RadioSwitchJaNei: React.FunctionComponent<Props> = ({name, selectedValue, setValue, children, ...rest}) => {
    return <RadioButtonInputGroup
        name={name}
        selectedValue={selectedValue}
        {...rest}
    >
        {({onColoredBg: _,...inputProps}) => (
            // onColoredBg skaper masse støy, men det skal fikses i ffe på ett eller annet tidspunkt
            <>
                {children}
                <RadioSwitch
                    onClick={e => setValue(name, e.currentTarget.value === 'true', { shouldValidate: true })}
                    leftLabel="Ja"
                    leftValue={true}
                    rightLabel="Nei"
                    rightValue={false}
                    {...inputProps}
                    className="ksmb-radio-switch--no-margin-top">
                </RadioSwitch>
            </>
        )}
    </RadioButtonInputGroup>

}
export default RadioSwitchJaNei;
