import React, {ReactNode, useLayoutEffect} from 'react';
import {useLocation} from 'react-router';

interface Props {
    children?: ReactNode | ReactNode[];
}

const ScrollToTop: React.FunctionComponent<Props> = ({children}) => {
    const location = useLocation();

    useLayoutEffect(() => {
        window.scrollTo(0, 0);
    }, [location])

    return (<>{children}</>);
}

export default ScrollToTop;
