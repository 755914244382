import isHoliday from 'holidays-nordic';
import {SettingsType} from '~/components/felles/Settings';
import { TZDate } from "@date-fns/tz";
import * as dateFns from 'date-fns';

const formatter = (s: string) => s.replace(':', '.');

const erHelg = (now: Date) => {
    return dateFns.isWeekend(now);
};

const erHelligdag = (now: Date) => {
    return isHoliday(now, 'no');
};

const erSommertid = (now: Date) => {
    const sommertidStart = new TZDate(dateFns.parse('15.05', 'dd.MM', new Date()), 'Europe/Oslo');
    const vintertidStart = new TZDate(dateFns.parse('15.09', 'dd.MM', new Date()), 'Europe/Oslo');
    return dateFns.isAfter(now, dateFns.sub(sommertidStart, {seconds: 1})) && dateFns.isBefore(now, vintertidStart);
}

export const erInnenforApningstidene = (settings: SettingsType): boolean => {
    const now = new Date();
    if (erHelg(now) || erHelligdag(now)) {
        return false;
    }
    const apnerTid: Date = new TZDate(dateFns.parse(settings.kontakt_apner, 'HH:mm', new Date()), 'Europe/Oslo');
    const stengerTxt = erSommertid(now) ? settings.kontakt_stenger_sommer : settings.kontakt_stenger_vinter;
    const stengerTid = new TZDate(dateFns.parse(stengerTxt, 'HH:mm', new Date()), 'Europe/Oslo');
    return dateFns.isAfter(now, dateFns.sub(apnerTid, {seconds: 1})) && dateFns.isBefore(now, stengerTid);
};

export const apner = (settings: SettingsType): string => settings.kontakt_apner;

export const stenger = (settings: SettingsType): string => {
    const now = new Date();
    return erSommertid(now) ? settings.kontakt_stenger_sommer : settings.kontakt_stenger_vinter;
};

export const apningstiderTekst = (settings: SettingsType): string => {
    return `Hverdager ${formatter(settings.kontakt_apner)} - ${formatter(stenger(settings))}`;
};

export const apningstiderTekstOppringning = (settings: SettingsType): string => {
    const now = new Date();
    const stengerTxt = erSommertid(now) ? settings.kontakt_stenger_sommer : settings.kontakt_stenger_vinter;
    return `Du vil bli oppringt mellom kl ${formatter(settings.kontakt_apner)} og ${formatter(
        stengerTxt)} på hverdager.`;
};
